import React from 'react'
import Collapse from './collapseEN'

const ServicesEN = () => {
    return (
        <section id='services' className='bg-bg-blue pt-16 pb-20 text-center px-4 '>
            <h2 classname=' mt-10 text-dark-blue '>Services</h2>
            <p className='text-gray  mx-auto max-w-md xl:max-w-4xl  text-2xl py-4'>We offer our clients the following services:</p>
            <div className='grid grid-cols-1 md:grid-cols-2 max-w-1170 mx-auto gap-x-44 gap-y-10 lg:gap-y-16 px-4 lg:px-0 mt-12 lg:mt-20'>
                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                <p className='usluge'>Keeping a general ledger</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 w-md'>
                <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                <p className='usluge'>Keeping tax records</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Analytical evidence of balance <br/> sheets, accounts, open items listings</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Calculation of depreciation of fixed assets</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Calculation of value added tax</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Making of final invoice</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Bookkeeping by cost centers and projects</p>
                </div>

                <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                    <div className='w-12'>
                    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                        <defs>
                        <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#24E8B9"/>
                        <stop offset="1" stop-color="#29A9F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                
                <p className='usluge'>Preparation of documentation for obtaining bank loans</p>
                </div>


            </div>
           <Collapse >
           <div className=' grid grid-cols-1 md:grid-cols-2 max-w-1170 mx-auto gap-x-44 gap-y-10 lg:gap-y-16 px-4 lg:px-0 mt-12 lg:mt-20 '>
                <div className='flex  flex-1 items-center space-x-8 max-w-md '>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Management of payment operations</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Drafting a complete accounting proposal</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Calculation of interests</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Drafting proposals for rationalization of other accounting procedures</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Preparation of Year-on-year balance sheets</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient> 
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Calculation of service contract and royalties</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Cash register maintaining</p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Retail accounting </p>
                    </div>

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Accounting of payroll and payroll contributions</p>
                    </div>
                    

                    <div className='flex  flex-1 items-center space-x-8 max-w-md'>
                        <div className='w-12'>
                        <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.2836 17.5602L16.8677 39.1006L6.63687 28.3122L12.3207 22.3187L16.8677 27.1135L30.8419 12.378C33.081 8.4186 31.6457 3.29686 28.0287 1.1174C26.9838 0.487778 25.7667 0.0276696 24.4002 0.00345337C18.1193 -0.10552 11.8155 2.36454 7.02727 7.41362C-2.34242 17.2938 -2.34242 33.325 7.02727 43.2052C16.397 53.0854 31.5998 53.0854 40.9695 43.2052C45.7576 38.1561 48.1001 31.5088 47.9967 24.8977C47.9738 23.2753 47.457 21.8707 46.7222 20.6841C44.6898 17.4149 40.7054 16.0709 37.2836 17.5602Z" fill="url(#paint0_linear_291_2406)"/>
                            <defs>
                            <linearGradient id="paint0_linear_291_2406" x1="-1.79138" y1="-0.262441" x2="41.977" y2="43.8481" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#24E8B9"/>
                            <stop offset="1" stop-color="#29A9F5"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        </div>
                    
                    <p className='usluge'>Other accounting services by the client's request</p>
                    </div>

                </div>

           </Collapse>
        </section>
    )
}

export default ServicesEN
